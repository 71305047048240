<template>
  <div class="flex flex-col h-full p-4 space-y-4">
    <div v-if="isStoredVehicles" class="flex-grow space-y-6">
      <div
        v-for="[key, vehicle] in selectedVehiclesStorage"
        :key="key"
        class="p-4 border rounded-lg shadow-sm"
      >
        <h2 class="text-xl font-semibold">{{ vehicle.vehicleName }}</h2>

        <div class="flex items-center justify-between mt-3">
          <div class="space-y-4">
            <p class="space-x-1 font-bold text-md">
              <span class="text-sm font-normal">{{ $t('startingFrom') }}</span>
              <span class="text-sm font-normal line-through text-cred">{{
                vehicle.vehiclePriceStrike
              }}</span
              >,
              <span>{{ vehicle.monthlyPrice }}</span>
              <span class="font-medium text-gray-700">€/Mo.</span>
            </p>

            <p class="text-sm font-medium text-wa-green">
              {{ $t('funnel.vehicleAvailable') }}
            </p>
          </div>

          <nuxt-img
            :src="`${vehicle.category}-${vehicle.subtype}.png`"
            class="object-cover drop-shadow-2xl md:max-h-18 max-h-16"
            loading="lazy"
            :alt="vehicle.vehicleName"
          />
        </div>
      </div>
    </div>

    <div v-else class="flex-grow">
      <div class="mb-10 space-y-2 md:mb-14">
        <h3 class="text-2xl font-semibold text-balance">
          {{ $t('funnel.emptyBasket.emptyCart[0]') }}
        </h3>
        <p class="md:text-lg">{{ $t('funnel.emptyBasket.emptyCart[1]') }}</p>
      </div>

      <div class="space-y-8">
        <div
          v-for="(item, index) in EMPTY_BASKET_INFO"
          :key="`emptyBasketInfo-${index}`"
          class="grid grid-cols-6 gap-3"
        >
          <component :is="item.icon" class="object-cover col-span-1" />
          <div class="col-span-5">
            <p class="text-lg font-semibold">
              {{ $t(`funnel.emptyBasket.${item.primaryText}`) }}
            </p>
            <p class="text-sm leading-snug md:text-base">
              {{ $t(`funnel.emptyBasket.${item.secondaryText}`) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="sticky flex justify-end mt-auto bg-white -bottom-6"
      :class="{ 'py-3': isStoredVehicles }"
    >
      <VButton
        to="/request-quote"
        :size="Size.md"
        :class="isStoredVehicles ? 'w-40' : 'w-48'"
        class="text-center"
        @click.prevent="onTrackEvent()"
      >
        {{ isStoredVehicles ? $t('next') : $t('funnel.emptyBasket.cta') }}
      </VButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import BookingIcon from '@/components/icons/BookingIcon.vue'
import FlexibleIcon from '@/components/icons/FlexibleIcon.vue'
import VehicleIcon from '@/components/icons/VehicleIcon.vue'
import { Size } from '@/types/enums'
import type { SelectedVehicleType } from '@/pages/request-quote/index.vue'

const { selectedVehiclesStorage = new Map() } = defineProps<{
  selectedVehiclesStorage?: Map<string, SelectedVehicleType>
}>()

const isStoredVehicles = computed((): boolean => {
  return !!selectedVehiclesStorage.size
})

const EMPTY_BASKET_INFO = [
  {
    icon: BookingIcon,
    primaryText: 'priceComparison[0]',
    secondaryText: 'priceComparison[1]',
  },
  {
    icon: FlexibleIcon,
    primaryText: 'largeSelection[0]',
    secondaryText: 'largeSelection[1]',
  },
  {
    icon: VehicleIcon,
    primaryText: 'fastAvailability[0]',
    secondaryText: 'fastAvailability[1]',
  },
]

function onTrackEvent() {
  useNuxtApp().$trackEvent('shopping_cart.cta.clicked', {
    count: selectedVehiclesStorage.size,
  })
}
</script>

<template>
  <div class="relative">
    <VButton
      class="flex items-center gap-2 md:p-2.5"
      :size="Size.sm"
      :variant="Variant.secondaryDark"
      @click="handleBasketClick"
    >
      <span class="sr-only">{{ $t('theNavigation.shoppingCart') }}</span>
      <BasketIcon class="w-6 h-6 md:w-4 md:h-4" aria-hidden="true" />
    </VButton>

    <div
      v-if="basketItemsCount > 0"
      class="absolute flex items-center justify-center w-4 h-4 text-xs text-white transform translate-x-1/2 -translate-y-1/2 rounded-full right-2 top-2 md:top-1 md:right-1 bg-cred"
    >
      {{ basketItemsCount }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { Size, Variant } from '@/types/enums'
import BasketIcon from './icons/BasketIcon.vue'

defineProps({
  basketItemsCount: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['basket-click'])

const handleBasketClick = () => {
  emit('basket-click')
}
</script>

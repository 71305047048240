declare global {
  interface Window {
    hj?: (action: string, value: string) => void
  }
}

// how to use:
// const experimentVariant = await useExperiment('frontpage-hero')

const EXPERIMENTS: { name: string; variants: string[] }[] = [
  {
    name: 'funnel',
    variants: ['v1', 'v2'],
  },
]

export const useExperiment = async (
  experimentName: string
): Promise<string | undefined> => {
  const experiment = EXPERIMENTS.find(
    (experiment) => experiment.name === experimentName
  )

  if (!experiment) {
    console.error(`experiment not found: ${experimentName}`)
    return undefined
  }

  const cookie = useCookie(`${experimentName}-experiment`)

  if (!cookie.value) {
    cookie.value =
      experiment.variants[
        Math.floor(Math.random() * experiment.variants.length)
      ]
    await nextTick()
  }

  if (process.client && window.hj) {
    window.hj('event', `${experimentName}-${cookie.value}`)
  }

  return cookie.value
}

// MARK: experiment helper

export const useFunnelRoute = async (category?: string): Promise<string> => {
  const route =
    (await useExperiment('funnel')) === 'v2'
      ? '/request-quote'
      : '/request/assets'

  return category ? `${route}?category=${encodeURIComponent(category)}` : route
}
